<template>
  <div>
    <v-tabs class="carousels" height="300px" width="300px">
      <div class="image-resource">
        <img
          src="https://revistaoeste.com/wp-content/uploads/2021/04/show-love-of-lesbian-barcelona-divulgacao-768x478.jpg"
          alt=""
        />
        <h1>Nome do evento TATATA</h1>
      </div>
      <div class="image-resource">
        <img
          src="https://revistaoeste.com/wp-content/uploads/2021/04/show-love-of-lesbian-barcelona-divulgacao-768x478.jpg"
          alt=""
        />
        <h1>Nome do evento TATATA</h1>
      </div>
      <div class="image-resource">
        <img
          src="https://revistaoeste.com/wp-content/uploads/2021/04/show-love-of-lesbian-barcelona-divulgacao-768x478.jpg"
          alt=""
        />
        <h1>Nome do evento TATATA</h1>
      </div>
      <div class="image-resource">
        <img
          src="https://revistaoeste.com/wp-content/uploads/2021/04/show-love-of-lesbian-barcelona-divulgacao-768x478.jpg"
          alt=""
        />
        <h1>Nome do evento TATATA</h1>
      </div>
      <div class="image-resource">
        <img
          src="https://revistaoeste.com/wp-content/uploads/2021/04/show-love-of-lesbian-barcelona-divulgacao-768x478.jpg"
          alt=""
        />
        <h1>Nome do evento TATATA</h1>
      </div>
      <div class="image-resource">
        <img
          src="https://revistaoeste.com/wp-content/uploads/2021/04/show-love-of-lesbian-barcelona-divulgacao-768x478.jpg"
          alt=""
        />
        <h1>Nome do evento TATATA</h1>
      </div>
    </v-tabs>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>

.image-resource {
    width: 60%;
    padding: 5px 20px;
}


.image-resource img{
    width: 90%;
    height: 80%;
    border-radius: 8px;
}
</style>
