import { render, staticRenderFns } from "./Carousels.vue?vue&type=template&id=bad1baa2&scoped=true&"
import script from "./Carousels.vue?vue&type=script&lang=js&"
export * from "./Carousels.vue?vue&type=script&lang=js&"
import style0 from "./Carousels.vue?vue&type=style&index=0&id=bad1baa2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bad1baa2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VTabs})
